import { ThemeProvider, unstable_createMuiStrictModeTheme } from '@mui/material';
import React from 'react';

export function ProvideTheme({ children }: React.PropsWithChildren<{}>) {
    const theme = unstable_createMuiStrictModeTheme({
        palette: {
            primary: {
                main: '#00446a',
                light: '#406f98',
                dark: '#001e3f',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#f08b1d',
                light: '#ffbc51',
                dark: '#b85d00',
                contrastText: '#000000',
            },
            background: {
                default: '#E1E2E1',
                paper: '#F5F5F6',
            },
        },
    });
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
