import { I2PEnvironment } from '@price-for-profit/micro-services';
import { Dashboard } from '@mui/icons-material';

const routeConfig: EnvironmentNavigation[] = [
    {
        application: {
            applicationName: 'Drive Account',
            env: {
                local: {
                    applicationId: '89e177ea-e69b-48c3-b6fa-88245af02bf5',
                    deployedUrl: 'https://localhost:5001/apps/account',
                },
                dev: {
                    applicationId: '89e177ea-e69b-48c3-b6fa-88245af02bf5',
                    deployedUrl: 'https://neograf.dev.insight2profit.com/apps/account',
                },
                sandbox: {
                    applicationId: '26b2c3c8-90e0-45ff-9a68-5f4c353878d7',
                    deployedUrl: 'https://neograf.sandbox.insight2profit.com/apps/account',
                },
                prod: {
                    applicationId: 'afa4f17f-a676-411a-9f13-bbc662ea0c75',
                    deployedUrl: 'https://neograf.insight2profit.com/apps/account',
                },
            },
        },
        links: [
            {
                title: 'Analytics Dashboard',
                url: '/analytics',
                icon: <Dashboard sx={{ color: 'primary.main' }} />,
            },
        ],
    },
];

interface NavigationLink {
    title: string;
    url: string;
    icon?: JSX.Element;
}

interface EnvironmentNavigation {
    application: {
        applicationName: string;
        env: Record<Exclude<I2PEnvironment, 'model'> | 'local', { applicationId: string; deployedUrl: string }>;
    };
    links: NavigationLink[];
}

interface DriveAccountNavigation {
    application: {
        applicationId: string;
        applicationName: string;
        deployedUrl: string;
    };
    links: NavigationLink[];
    local?: boolean;
}

interface BuildDriveAccountNavigationProps {
    applicationId: string;
    environment: Exclude<I2PEnvironment, 'model'>;
    useLocal?: boolean;
}

export function buildDriveAccountNavigation({
    applicationId,
    environment,
    useLocal,
}: BuildDriveAccountNavigationProps): DriveAccountNavigation[] {
    const localEnvironment = environment === 'dev' && useLocal ? 'local' : environment;

    return routeConfig.map<DriveAccountNavigation>(({ links, application }) => {
        const envConfig = application.env[localEnvironment];
        return {
            application: {
                applicationName: application.applicationName,
                ...envConfig,
            },
            links,
            local: applicationId === envConfig.applicationId,
        };
    });
}

export const showHamburger = routeConfig.length > 1;
