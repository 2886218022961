import { ListItemButton, useTheme } from '@mui/material';
import { NavLinkProps, NavLink } from 'react-router-dom';
import React from 'react';
const ActiveNavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
    const theme = useTheme();
    return (
        <NavLink
            {...props}
            style={({ isActive }) => ({ backgroundColor: isActive ? theme.palette.action.selected : undefined })}
            end
            ref={ref}
        />
    );
});

interface NavigationLinkProps {
    local: boolean;
    url: string;
    deployedUrl: string;
    onClose(): void;
}
export function NavigationLink({
    local,
    url,
    deployedUrl,
    children,
    onClose,
}: React.PropsWithChildren<NavigationLinkProps>) {
    if (local) {
        return (
            <ListItemButton onClick={onClose} component={ActiveNavLink} to={url}>
                {children}
            </ListItemButton>
        );
    }
    return (
        <ListItemButton component='a' href={new URL(`${deployedUrl}${url}`).toString()}>
            {children}
        </ListItemButton>
    );
}
