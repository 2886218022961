import { Box, Paper, Theme } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { determineHeaderColor } from '../../components';
import { useInsightEnvironment } from '../../hooks';

export function LoginLayout({ children, serviceBaseUrl }: PropsWithChildren<{ serviceBaseUrl: string }>) {
    const environment = useInsightEnvironment({ serviceBaseUrl });

    return (
        <Box
            sx={{
                display: 'grid',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                backgroundColor: determineHeaderColor(environment) || 'primary.main',
            }}
        >
            <Paper
                sx={(theme: Theme) => ({
                    width: theme.typography.pxToRem(425),
                    height: theme.typography.pxToRem(350),
                })}
            >
                {children}
            </Paper>
        </Box>
    );
}
