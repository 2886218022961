import { Toolbar } from '@mui/material';
import { useInsightEnvironment } from '../../hooks/use-insight-environment';
import React from 'react';
import { I2PEnvironment } from '@price-for-profit/micro-services';
export const determineHeaderColor = (environment: I2PEnvironment) =>
    environment === 'dev' ? 'common.black' : environment === 'sandbox' ? 'secondary.main' : undefined;

export function ColoredToolbar({ children, serviceBaseUrl }: React.PropsWithChildren<{ serviceBaseUrl: string }>) {
    const environment = useInsightEnvironment({ serviceBaseUrl });
    return (
        <Toolbar
            sx={{
                display: 'grid',
                gridTemplateColumns: '1fr .75fr 1fr',
                backgroundColor: determineHeaderColor(environment),
                alignContent: 'center',
            }}
        >
            {children}
        </Toolbar>
    );
}
