import { Menu } from '@mui/icons-material';
import { Box, Drawer, IconButton, Stack, Theme } from '@mui/material';
import React from 'react';
import { SUPPORT_PORTAL_LINK } from '../../constants';
import { ColoredToolbar, Header, SupportMenu, UserMenu } from '../header';
import { LeftNavigation } from './left-navigation';
import { showHamburger } from './navigation-config';
import { useNavigationState } from './use-navigation-state';

const DRAWER_WIDTH = 250;

export interface NavigationProps {
    logo: JSX.Element;
    footerLogo: JSX.Element;
    serviceBaseUrl: string;
    applicationId: string;
    toolbar?: {
        rightComponent?: React.ReactNode;
        leftComponent?: React.ReactNode;
    };
    navigation?: {
        trainingGuideLink?: string;
    };
    useLocal: boolean;
}

export function Navigation({
    children,
    serviceBaseUrl,
    logo,
    applicationId,
    useLocal,
    toolbar = {},
    navigation = {},
    footerLogo,
}: React.PropsWithChildren<NavigationProps>) {
    const { state, toggleLeft } = useNavigationState();

    return (
        <>
            <Header serviceBaseUrl={serviceBaseUrl}>
                <Box justifySelf='start'>
                    <Stack direction='row' spacing={1} justifyContent='baseline' alignItems='center'>
                        {showHamburger && (
                            <IconButton id='drive-account-navigation-button' onClick={toggleLeft}>
                                <Menu sx={{ color: 'common.white' }} />
                            </IconButton>
                        )}
                        {toolbar?.leftComponent}
                    </Stack>
                </Box>
                <Box justifySelf='center'>{logo}</Box>
                <Box justifySelf='end'>
                    <Stack direction='row' spacing={1}>
                        {toolbar?.rightComponent}
                        <SupportMenu
                            supportPortalLink={SUPPORT_PORTAL_LINK}
                            trainingGuideLink={navigation.trainingGuideLink}
                        />
                        <UserMenu />
                    </Stack>
                </Box>
            </Header>
            <Drawer onClose={toggleLeft} anchor='left' open={state.left}>
                <Box
                    width={DRAWER_WIDTH}
                    height={(theme: Theme) =>
                        `calc(100% - ${Number(theme.mixins.toolbar.minHeight)}px - ${theme.spacing(2)})`
                    }
                >
                    <ColoredToolbar serviceBaseUrl={serviceBaseUrl} />
                    <LeftNavigation
                        onClose={toggleLeft}
                        useLocal={useLocal}
                        applicationId={applicationId}
                        serviceBaseUrl={serviceBaseUrl}
                        footerLogo={footerLogo}
                    />
                </Box>
            </Drawer>
            <Box component='main'>{children}</Box>
        </>
    );
}
