import { Box, Typography } from '@mui/material';
import React from 'react';

const developmentLinks = [
    { label: 'Material UI', link: 'https://mui.com/material-ui/getting-started/overview/' },
    { label: 'React Query', link: 'https://tanstack.com/query/v4' },
    { label: 'React Hook Form', link: 'https://react-hook-form.com/' },
    { label: 'React Router', link: 'https://reactrouter.com/' },
    { label: 'TypeScript', link: 'https://www.typescriptlang.org/' },
    { label: 'Hookstate', link: 'https://hookstate.js.org/' },
];

export function Demo() {
    return (
        <Box
            sx={{
                height: 300,
                display: 'grid',
                justifyItems: 'center',
                alignItems: 'center',
            }}
        >
            <Typography variant='h3'>Start Development</Typography>
            <Typography variant='h6'>Development Links</Typography>
            <Box
                sx={{
                    display: 'flex',
                    width: 500,
                    justifyContent: 'space-between',
                    '& a': {
                        textDecoration: 'none',
                    },
                }}
            >
                {developmentLinks.map(tool => (
                    <a key={tool.label} href={tool.link} target='_blank' rel='noreferrer'>
                        {tool.label}
                    </a>
                ))}
            </Box>
        </Box>
    );
}
