import { I2PEnvironment } from '@price-for-profit/micro-services';

interface UseInsightEnvironmentProps {
    serviceBaseUrl: string;
}

export function useInsightEnvironment({
    serviceBaseUrl,
}: UseInsightEnvironmentProps): Exclude<I2PEnvironment, 'model'> {
    if (serviceBaseUrl.includes('modeling') || serviceBaseUrl.includes('dev')) {
        return 'dev';
    } else if (serviceBaseUrl.includes('sandbox')) {
        return 'sandbox';
    } else {
        return 'prod';
    }
}
