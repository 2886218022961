import React from 'react';

export function useLocalStorage<T, K = T extends Object ? Partial<T> : T>(key: string, initialValue: K) {
    const [storedValue, setStoredValue] = React.useState<K>(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });

    const remove = React.useCallback(() => {
        window.localStorage.removeItem(key);
        setStoredValue(initialValue);
    }, [key, initialValue]);

    React.useEffect(() => {
        try {
            window.localStorage.setItem(key, JSON.stringify(storedValue));
        } catch (error) {
            console.log(error);
        }
    }, [storedValue, key]);

    return [storedValue, setStoredValue, remove] as const;
}
