import { hookstate, useHookstate } from '@hookstate/core';

interface AuthState {
    user: app.UserInfo | null;
}

const initialState: AuthState = {
    user: null,
};

const authState = hookstate(initialState);

export function useAuthState() {
    return useHookstate(authState);
}
