import { InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { AuthInProgress } from '../providers';
import React from 'react';
import { Box } from '@mui/material';

export function AuthenticationRequired({ children }: React.PropsWithChildren<{}>) {
    useMsalAuthentication(InteractionType.Redirect);
    return (
        <>
            <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Box sx={{ pt: 4 }}>
                    <AuthInProgress />
                </Box>
            </UnauthenticatedTemplate>
        </>
    );
}
