import React from 'react';

type Action = { type: 'left' } | { type: 'right' };

interface NavigationState {
    left: boolean;
    right: boolean;
}

const defaultNavigationState: NavigationState = {
    left: false,
    right: false,
};

function navigationStateReducer(state: NavigationState, action: Action): NavigationState {
    switch (action.type) {
        case 'left':
            return { ...state, left: !state.left };
        case 'right':
            return { ...state, right: !state.right };
        default:
            return state;
    }
}
export function useNavigationState() {
    const [state, dispatch] = React.useReducer(navigationStateReducer, defaultNavigationState);

    const toggleRight = () => {
        dispatch({ type: 'right' });
    };
    const toggleLeft = () => {
        dispatch({ type: 'left' });
    };
    return { state, toggleLeft, toggleRight };
}
