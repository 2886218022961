import React from 'react';
import { DefaultAppConfig } from '../types';

interface ServiceProviderProps<Config extends DefaultAppConfig = DefaultAppConfig> {
    context: app.ServiceContext<Config>;
}

const serviceContext = React.createContext<app.ServiceContext>({} as app.ServiceContext);

export function ProvideService<Config extends DefaultAppConfig>({
    children,
    context,
}: React.PropsWithChildren<ServiceProviderProps<Config>>) {
    return <serviceContext.Provider value={context}>{children}</serviceContext.Provider>;
}

export function useService<Config extends DefaultAppConfig = DefaultAppConfig>() {
    return React.useContext(serviceContext) as app.ServiceContext<Config>;
}
