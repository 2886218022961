import { DefaultAppConfig } from '../types';

export const getAppConfig = async <T extends DefaultAppConfig>(
    baseUrl: string,
    googleTagId: string = ''
): Promise<T> => {
    const response = await fetch(`${baseUrl}/api/config`);
    const config: T = await response.json();
    return { ...config, googleTagId: googleTagId };
};
