import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AccountCircle, Logout } from '@mui/icons-material';
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useMenu } from '../../hooks/use-menu';
import { useUser } from '../../hooks/use-user';
import React from 'react';

const USER_MENU_BUTTON = `user-menu-button`;
const USER_MENU_NAME = `user-menu`;

export function UserMenu() {
    const [anchorEl, openMenu, closeMenu, open] = useMenu();

    const { instance } = useMsal();
    const authenticated = useIsAuthenticated();
    const user = useUser();

    const logout = () => {
        instance.logout();
    };

    if (!user || !authenticated) {
        return null;
    }

    return (
        <>
            <Menu
                id={USER_MENU_NAME}
                anchorEl={anchorEl}
                open={open}
                onClose={closeMenu}
                MenuListProps={{
                    'aria-labelledby': USER_MENU_BUTTON,
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <MenuItem unselectable='off'>
                    <ListItemIcon>
                        <AccountCircle />
                    </ListItemIcon>
                    <ListItemText primary={user.displayName} secondary={user.email} />
                </MenuItem>
                <Divider />
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <Logout />
                    </ListItemIcon>
                    <ListItemText primary='Logout' />
                </MenuItem>
            </Menu>
            <IconButton
                size='large'
                id={USER_MENU_BUTTON}
                aria-controls={open ? USER_MENU_NAME : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={openMenu}
                sx={{
                    justifySelf: 'end',
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    color: 'primary.contrastText',
                }}
            >
                <AccountCircle />
            </IconButton>
        </>
    );
}
